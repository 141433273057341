import { useNavigate, useLocation, Outlet } from "react-router-dom";
import useFirebaseAuthentication from "../../common/hooks/useFirebaseAuthentication";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { userState } from "../../common/recoilStateDefs";
import { useRecoilState } from "recoil";
import { User as FirebaseUser } from "firebase/auth";
import NavigationHeader from "../../common/components/layout/NavigationHeader";
import WedgeLoader from "../../common/components/WedgeLoader/WedgeLoader";
import logoPlaceholder from "../../assets/img/cm-logo.png";
import profilePicPlaceholder from "../../assets/img/avatar-placeholder-sm.jpg";

const navigation = [
  { name: "Inventory", href: "/inventory" },
  { name: "Inventory Review", href: "/review" },
  { name: "Orders & Inquiries", href: "/inquiries" },
];

const userNavigation = [{ name: "Sign out", href: "/signout" }];

export default function PortalContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { authUser, authIsLoading } = useFirebaseAuthentication();
  const [user, setUser] = useRecoilState(userState);

  useEffect(() => {
    if (!authUser) return;
    fetchUserDataFromFirebase(authUser);
  }, [authUser]);

  const fetchUserDataFromFirebase = (authUser: FirebaseUser) => {
    console.log("Fetching user data");
    getDoc(doc(db, "users", authUser.uid))
      .then((dbUserSnap) => {
        if (dbUserSnap.exists()) {
          console.log("User data found");
          const data = dbUserSnap.data();
          setUser({
            id: authUser.uid,
            email: data.email,
            displayName: data.displayName || authUser.displayName || "",
            supplier: data.supplier,
            roles: data.roles,
          });
        } else console.log("User data not found");
      })
      .catch((err) => {
        console.log(`Error fetching user data: ${err}`);
      });
  };

  if (authIsLoading) {
    return <FullPageLoadingScreen />;
  }

  if (!authUser) {
    navigate(
      `/signin?redirect=${encodeURIComponent(
        location.pathname + location.search,
      )}`,
    );
  }

  if (!user) {
    return <FullPageLoadingScreen />;
  }

  if (!(user.roles || []).includes("internal")) {
    return <>Unauthorized</>;
  }

  return (
    <div className="min-h-full">
      <NavigationHeader
        logoImg={logoPlaceholder}
        userProfileImg={profilePicPlaceholder}
        user={user}
        navigationMenu={navigation}
        userMenu={userNavigation}
      />
      <div className="mx-auto max-w-7xl lg:px-8">
        <Outlet />
      </div>
    </div>
  );
}

function FullPageLoadingScreen() {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <WedgeLoader />
      <div className="text-lg w-full text-center">Loading...</div>
    </div>
  );
}
