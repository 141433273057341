import { ComponentProps, PropsWithChildren, useEffect, useState } from "react";
import { collection, limit, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import {
  saveInputChangeInHookState,
  sendAuthenticatedRequest,
} from "../../common/functions";
import AsyncSelect from "react-select/async";
import { add, set } from "lodash";

export default function InventoryUploadReview() {
  const [dataToReview, setDataToReview] = useState<any | any[]>();
  const [noData, setNoData] = useState(false);
  const [editableData, setEditableData] = useState<string>("{}");
  const [validationErrors, setValidationErrors] = useState<any[] | undefined>();
  const [submissionStatus, setSubmissionStatus] = useState<
    string | undefined
  >();
  const [original, setOriginal] = useState<any | undefined>();

  useEffect(() => {
    const collectionRef = collection(db, `inventory-landing-zone`);
    const q = query(collectionRef, where("humanReview", "==", true), limit(1));

    getDocs(q).then((qs) => {
      if (qs.docs.length === 0) {
        console.log("Length is 0");
        setNoData(true);
      } else {
        console.log("Length is not 0");
        const data = qs.docs[0].data();
        setDataToReview(data);
        if (data.status.latestStage === "extraction") {
          console.log("extraction");
          console.log(data);
          setOriginal(data.status.context.sourceProductUpdate);
          if (data.status.context.extracted) {
            setEditableData(
              JSON.stringify(data.status.context.extracted, null, 2),
            );
          } else {
            setEditableData(JSON.stringify(data.productUpdate, null, 2));
          }
        } else {
          setEditableData(JSON.stringify(data.productUpdate, null, 2));
        }
      }
    });
  }, []);

  const validateData = () => {
    setValidationErrors(undefined);
    let dataAsJson = {};
    try {
      dataAsJson = JSON.parse(editableData);
    } catch (err) {
      setValidationErrors(["Is not valid JSON"]);
      return;
    }
    if (Array.isArray(dataAsJson)) {
      if (dataAsJson.length !== 1) {
        setValidationErrors(["Only one product per line is supported"]);
        return;
      }
      dataAsJson = dataAsJson[0];
    }
    sendAuthenticatedRequest(
      `${process.env.REACT_APP_INTERNAL_INVENTORY_API_BASE_URL}/validate`,
      {
        method: "POST",
        jsonBody: dataAsJson,
      },
    )
      .then((resp) => resp.json())
      .then(setValidationErrors);
  };

  const submitStatusUpdate = (accepted: boolean) => {
    setSubmissionStatus("Loading...");
    let toSubmit = JSON.parse(editableData);
    if (Array.isArray(toSubmit)) {
      if (toSubmit.length !== 1 && accepted) {
        setSubmissionStatus(
          "Extracting more (or less) than one product per input line currently not supported",
        );
        return;
      }
      toSubmit = toSubmit[0];
    }
    sendAuthenticatedRequest(
      `${process.env.REACT_APP_INTERNAL_INVENTORY_API_BASE_URL}/correction`,
      {
        method: "POST",
        jsonBody: {
          previousEvent: dataToReview,
          correctedProductUpdate: accepted ? toSubmit : undefined,
          rejected: accepted ? undefined : true,
        },
      },
    )
      .then((resp) => setSubmissionStatus("Submitted!"))
      .catch((err) => setSubmissionStatus("Submission failed"));
  };

  function addNewProperties(A: any, B: any) {
    for (let key in B) {
      if (B.hasOwnProperty(key)) {
        if (Array.isArray(B[key]) && Array.isArray(A[key])) {
          // If both fields are arrays, concatenate them
          A[key] = A[key].concat(B[key]);
        } else if (
          typeof B[key] === "object" &&
          B[key] !== null &&
          typeof A[key] === "object" &&
          A[key] !== null
        ) {
          // If both fields are objects, recursively apply the logic
          addNewProperties(A[key], B[key]);
        } else if (A[key] === undefined) {
          // If the field is not present in A, add it
          A[key] = B[key];
        }
      }
    }
    return A;
  }

  const addToEditableData = (data: any) => {
    const editableDataObject = JSON.parse(editableData);
    const updatedData = addNewProperties(editableDataObject, data);
    console.log("Updated data", updatedData);
    setEditableData(JSON.stringify(updatedData, null, 2));
  };

  const addSummary = () => {
    sendAuthenticatedRequest(
      `${process.env.REACT_APP_INTERNAL_INVENTORY_API_BASE_URL}/summarize`,
      {
        method: "POST",
        jsonBody: original,
      },
    )
      .then((resp) => resp.json())
      .then((data) => addToEditableData(data));
  };

  const standardizeDimensions = () => {
    sendAuthenticatedRequest(
      `${process.env.REACT_APP_INTERNAL_INVENTORY_API_BASE_URL}/standardize-dimensions`,
      {
        method: "POST",
        jsonBody: JSON.parse(editableData),
      },
    )
      .then((resp) => resp.json())
      .then((data) => addToEditableData(data));
  };

  if (noData) {
    return <>Nothing to review!</>;
  }
  if (!dataToReview) return <>Loading...</>;

  return (
    <div
      style={{
        marginLeft: "20px",
        marginRight: "20px",
        marginTop: "100px",
        marginBottom: "100px",
      }}
    >
      <h2 className="text-lg font-semibold mb-3">
        Review Inventory Upoload Data
      </h2>
      <div className="text-slate-700 mb-8 text-sm">
        <span>
          Stage:{" "}
          <span className="font-semibold">
            {dataToReview.status.latestStage}
          </span>
        </span>{" "}
        <span className="ml-2">
          Status:{" "}
          <span className="font-semibold">
            {dataToReview.status.latestStageResult}
          </span>
        </span>
      </div>
      {dataToReview.status.latestStage === "extraction" && (
        <>
          <div className="mb-8">
            <div className="font-semibold mb-2">Original Data</div>
            <div className="p-2 border border-gray-200 rounded">
              <pre>{JSON.stringify(original, null, 2)}</pre>
            </div>
          </div>
          <div className="font-semibold mb-2">Review</div>
        </>
      )}
      <div>
        <div>
          Add boilerplate:
          <button
            className="mx-1 bg-blue-500 hover:bg-blue-700 text-white text-sm py-1 px-2 rounded"
            onClick={() => {
              addToEditableData({ id: original.id });
            }}
          >
            ID
          </button>
          <button
            className="mx-1 bg-blue-500 hover:bg-blue-700 text-white text-sm py-1 px-2 rounded"
            onClick={() => {
              addToEditableData({
                dimensions: { length: { value: 0, unit: "" } },
              });
            }}
          >
            Length
          </button>
          <button
            className="mx-1 bg-blue-500 hover:bg-blue-700 text-white text-sm py-1 px-2 rounded"
            onClick={() => {
              addToEditableData({
                dimensions: { XXXX: { value: 0, unit: "" } },
              });
            }}
          >
            Custom Dimension
          </button>
          <button
            className="mx-1 bg-blue-500 hover:bg-blue-700 text-white text-sm py-1 px-2 rounded"
            onClick={() => {
              addToEditableData({ shape: "" });
            }}
          >
            Shape
          </button>
          <button
            className="mx-1 bg-blue-500 hover:bg-blue-700 text-white text-sm py-1 px-2 rounded"
            onClick={() => {
              addToEditableData({
                price: { value: 0, currency: "", unit: "" },
              });
            }}
          >
            Price
          </button>
          <button
            className="mx-1 bg-blue-500 hover:bg-blue-700 text-white text-sm py-1 px-2 rounded"
            onClick={() => {
              addToEditableData({
                quantity: { value: 0, unit: "" },
              });
            }}
          >
            Quantity
          </button>
          <button
            className="mx-1 bg-blue-500 hover:bg-blue-700 text-white text-sm py-1 px-2 rounded"
            onClick={addSummary}
          >
            Summary (generate)
          </button>
          <button
            className="mx-1 bg-blue-500 hover:bg-blue-700 text-white text-sm py-1 px-2 rounded"
            onClick={standardizeDimensions}
          >
            Standard dimensions (compute)
          </button>
        </div>
        <textarea
          rows={20}
          style={{ width: "100%" }}
          value={editableData}
          onChange={saveInputChangeInHookState(setEditableData)}
        />
      </div>
      <div style={{ marginTop: "40px" }}>
        <MaterialSelect
          onSubmit={(selectedMaterials) =>
            addToEditableData({ material: { dbMaterials: selectedMaterials } })
          }
        />
      </div>
      <div style={{ marginTop: "40px" }}>
        <h3>Validation</h3>
        {/* {validationErrors === undefined && <>Loading...</>} */}
        {validationErrors !== undefined && validationErrors.length === 0 && (
          <>Valid!</>
        )}
        {validationErrors !== undefined && validationErrors.length > 0 && (
          <pre>{JSON.stringify(validationErrors, null, 2)}</pre>
        )}
      </div>
      <div style={{ margin: "40px" }}>
        <Button onClick={validateData}>Validate</Button>
        <Button
          onClick={() => submitStatusUpdate(true)}
          disabled={
            validationErrors === undefined || validationErrors.length > 0
          }
        >
          Submit Correction
        </Button>
        <Button onClick={() => submitStatusUpdate(false)}>
          Can't Fix This
        </Button>
        {submissionStatus}
      </div>
    </div>
  );
}

function Button(props: PropsWithChildren<ComponentProps<"button">>) {
  return (
    <button
      className="mr-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      {...props}
    >
      {props.children}
    </button>
  );
}

function MaterialSelect({
  onSubmit,
}: {
  onSubmit?: (selectedMaterials: any[]) => void;
}) {
  const [selectedMaterials, setSelectedMaterials] = useState<any[]>([]);
  const loadMaterials = async (inputValue: string) => {
    const response = await sendAuthenticatedRequest(
      `${process.env.REACT_APP_INTERNAL_INVENTORY_API_BASE_URL}/materials/search?q=${inputValue}`,
      {},
    );
    const data = await response.json();
    return data.map((material: string) => ({
      value: material,
      label: material,
    }));
  };

  return (
    <>
      <AsyncSelect
        isMulti
        value={selectedMaterials}
        loadOptions={loadMaterials}
        onChange={(selectedOption) => {
          setSelectedMaterials(selectedOption as any[]);
        }}
      />
      <button
        onClick={() => {
          onSubmit && onSubmit(selectedMaterials.map((m) => m.value));
          setSelectedMaterials([]);
        }}
        className="mr-3 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Set materials
      </button>
    </>
  );
}
