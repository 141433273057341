import { collection, onSnapshot, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { ReactNode, useEffect, useState } from "react";
import SimpleTable from "../../common/components/SimpleTable/SimpleTable";
import DetailsModal from "../../common/components/DetailsModal/DetailsModal";

type ArbitraryData = {
  [key: string]: any;
};

export default function InquiryBrowser() {
  const [data, setData] = useState<ArbitraryData[] | undefined>(undefined);
  const [error, setError] = useState(false);
  const [modalData, setModalData] = useState<
    { title: string; content: ReactNode } | undefined
  >();

  useEffect(() => {
    const collectionRef = collection(db, "inquiries");

    // Super simple staring position: just get all inquiries with no pagination or nothing.
    const unsubscribe = onSnapshot(collectionRef, (snap) => {
      setData(snap.docs.map((d) => d.data() as ArbitraryData));
    });

    return () => unsubscribe();
  }, []);

  if (error) {
    return <div>Error loading inquiries</div>;
  }
  if (data === undefined) {
    return <div>Loading</div>;
  }

  if (data.length === 0) {
    return <>No inquiries yet</>;
  }

  const columnTitles = ["Type", "Name", "Email", "Product", "Received", ""];

  return (
    <div className="mt-10">
      <SimpleTable
        columnTitles={columnTitles}
        data={data.map((aid) => ({
          Type: aid.inquiryType || "inquiry",
          Name: aid.name,
          Email: aid.email,
          Product: aid.product?.summary,
          Received: aid.timestamp?.toDate().toLocaleDateString(),
          "": (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setModalData({
                  title: "Details",
                  content: <pre>{JSON.stringify(aid, null, 2)}</pre>,
                });
              }}
              className="hover:text-teal-500 border-b border-dashed"
            >
              View details
            </a>
          ),
        }))}
      />
      {modalData && (
        <DetailsModal {...modalData} onClose={() => setModalData(undefined)} />
      )}
    </div>
  );
}
