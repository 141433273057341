import { Fragment, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import { Product } from "../../common/models/product";
import { classNames } from "../../common/functions";
import SearchResultsGrid from "./SearchResultsGrid";
import { uniq } from "lodash";

const dimUnitOptions = [
  { name: "in", value: "in" },
  { name: "ft", value: "ft" },
  { name: "mm", value: "mm" },
  { name: "m", value: "m" },
];

type SearchResultsViewProps = {
  products: Product[];
  query?: string;
  structuredQuery?: any;
};

type AppliedFilter = {
  filter: string;
  filterCategory?: string;
  value: string;
};

export default function SearchResultsView({
  products,
  query,
  structuredQuery,
}: SearchResultsViewProps) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>([]);
  const [dimUnit, setDimUnit] = useState("in");

  const extractUniqueFromProducts = (extractFn: (p: Product) => string) => {
    const result = uniq(products.map(extractFn));
    result.sort();
    return result;
  };

  const extractCompositeMaterialAlloy = (p: Product) =>
    `${p.material} ${p.alloy}`;

  const isFilterApplier = (filterId: string, filterValue: string): boolean => {
    return (
      appliedFilters.filter(
        (af) => af.filter == filterId && af.value == filterValue,
      ).length > 0
    );
  };

  const onFilterChange = (filterId: string, filterValue: string) => {
    if (isFilterApplier(filterId, filterValue)) {
      setAppliedFilters(
        appliedFilters.filter(
          (af) => !(af.filter === filterId && af.value === filterValue),
        ),
      );
    } else {
      setAppliedFilters([
        ...appliedFilters,
        { filter: filterId, value: filterValue },
      ]);
    }
  };

  const getRelevantPrroductValue = (p: Product, filterId: string) => {
    if (filterId == "material") return extractCompositeMaterialAlloy(p);
    return p[filterId as keyof Product] as string;
  };

  /**
   * Determine whether this product should be displayed based on the filters selected.
   * @param p Product search result
   * @returns
   */
  const productFilterFn = (p: Product): boolean => {
    const byFilter = new Map<string, AppliedFilter[]>();
    appliedFilters.forEach((af) => {
      if (!byFilter.has(af.filter)) byFilter.set(af.filter, []);
      byFilter.get(af.filter)?.push(af);
    });

    // Include this product if it matches at least one value in every active filter.
    return Array.from(byFilter.entries()).every(([filterId, afs]) => {
      return afs
        .map((af) => getRelevantPrroductValue(p, af.filter) === af.value)
        .some((v) => v);
    });
  };

  const filters = [
    {
      id: "material",
      name: "Material",
      options: extractUniqueFromProducts(extractCompositeMaterialAlloy).map(
        (m) => {
          return { value: m, label: m, checked: false };
        },
      ),
    },
    {
      id: "shape",
      name: "Shape",
      options: extractUniqueFromProducts((p) => p.shape).map((m) => {
        return { value: m, label: m, checked: false };
      }),
    },
    {
      id: "supplierName",
      name: "Supplier",
      options: extractUniqueFromProducts((p) => p.supplierName || "")
        .filter((s) => !!s)
        .map((m) => {
          return { value: m, label: m, checked: false };
        }),
    },
  ];

  if (products.length === 0) return <></>;

  const resultsToDisplay = products.filter(productFilterFn);

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    <div className="text-red-800 px-4 py-6 text-sm">
                      These filters only affect the already retrieved items!
                    </div>

                    {filters.map((section) => (
                      <Disclosure
                        as="div"
                        key={section.id}
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">
                                  {section.name}
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div
                                    key={option.value}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`filter-mobile-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      defaultValue={option.value}
                                      type="checkbox"
                                      defaultChecked={isFilterApplier(
                                        section.id,
                                        option.value,
                                      )}
                                      onChange={(e) =>
                                        onFilterChange(section.id, option.value)
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                      className="ml-3 min-w-0 flex-1 text-gray-500"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-24">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              <span className="text-gray-400">Results for</span> {query}
            </h1>

            <div className="flex items-center">
              <div className="text-sm font-medium text-gray-700 hover:text-gray-900">
                {resultsToDisplay.length} results
              </div>
              <button
                className="text-sm font-medium text-gray-700 hover:text-gray-900 ml-5 sm:ml-7"
                onClick={() => setAppliedFilters([])}
              >
                Clear filters
              </button>
              <Menu
                as="div"
                className="relative inline-block text-left ml-5 sm:ml-7"
              >
                <div>
                  <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    Unit: {dimUnit}
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {dimUnitOptions.map((option) => (
                        <Menu.Item key={option.name}>
                          {({ active }) => (
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setDimUnit(option.value);
                              }}
                              className={classNames(
                                option.value == dimUnit
                                  ? "font-medium text-gray-900"
                                  : "text-gray-500",
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm",
                              )}
                            >
                              {option.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>

              <button
                type="button"
                className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
              >
                <span className="sr-only">View grid</span>
                <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                onClick={() => setMobileFiltersOpen(true)}
              >
                <span className="sr-only">Filters</span>
                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>

          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <h2 id="products-heading" className="sr-only">
              Products
            </h2>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="hidden lg:block">
                {filters.map((section) => (
                  <Disclosure
                    as="div"
                    key={section.id}
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              {section.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.value}
                                  type="checkbox"
                                  checked={isFilterApplier(
                                    section.id,
                                    option.value,
                                  )}
                                  onChange={(e) =>
                                    onFilterChange(section.id, option.value)
                                  }
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {option.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
                <div className="text-red-800 py-6 text-sm">
                  These filters only affect the already retrieved items!
                </div>
              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">
                <SearchResultsGrid
                  products={resultsToDisplay}
                  dimensionUnit={dimUnit}
                  structuredQuery={structuredQuery}
                />
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
