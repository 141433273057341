import {
  ClipboardDocumentListIcon,
  BanknotesIcon,
  BuildingOfficeIcon,
  LinkIcon,
  ExclamationCircleIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as ShapesIcon } from "../../assets/img/shapes.svg";
import { ReactComponent as DimensionsIcon } from "../../assets/img/dimensions.svg";
import { ReactComponent as CountIcon } from "../../assets/img/count.svg";
import { Product } from "../../common/models/product";
import { convertDimensionUnits } from "./helpers";
import { Tooltip } from "react-tooltip";

const SearchResultsGrid = ({
  products,
  dimensionUnit,
  structuredQuery,
}: {
  products: Product[];
  dimensionUnit: string;
  structuredQuery: any;
}) => {
  const DIFFERENT_MATERIAL = "different-material";
  const DIFFERENT_DIMENSIONS = "different-dimensions";
  const DIFFERENT_MATERIAL_AND_DIMENSIONS = "different-material-and-dimensions";

  const formatPrice = (product: Product): string => {
    if (product.price === undefined || product.price === null) return "-";

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: product.price.currency,
    });

    return `${formatter.format(product.price.value)} per ${product.price.unit}`;
  };

  const formatDimensions = (product: Product, unit: string) => {
    if (!product.dimensions) return "-";

    return (
      Object.entries(product.dimensions)
        .map(([label, d]) => {
          const fromUnit = d.unit || "ft";
          const numDecimals = unit === "mm" ? 0 : 2;
          return convertDimensionUnits(fromUnit, unit, d.value).toFixed(
            numDecimals,
          );
        })
        .join(" x ") +
      " " +
      unit
    );
  };

  const sourceCategoryBadge = (product: Product) => {
    if (product.sourceCategory === "scraping") {
      return (
        <span className="bg-amber-500 text-gray-200 text-xs px-2 py-1 rounded-full inline-block">
          scraped
        </span>
      );
    }
    return (
      <span className="bg-blue-500 text-gray-200 text-xs px-2 py-1 rounded-full inline-block">
        uploaded
      </span>
    );
  };

  const extractMaterialArray = (p: Product) => {
    if (typeof p.material === "string") {
      const result = [];
      if (p.material) result.push(p.material);
      if (p.alloy) result.push(p.alloy);
      if (p.grade) result.push(p.grade);
      return result;
    }
    if (p.material && Array.isArray(p.material.dbMaterials)) {
      return p.material.dbMaterials;
    }
    if (p.material && p.material.text) {
      return Object.values(p.material.text);
    }
    return [];
  };

  const extractMaterialString = (p: Product) => {
    return extractMaterialArray(p).join(" ");
  };

  const isMaterialMatch = (p: Product, material: string) => {
    return extractMaterialArray(p).some(
      (m) => m.toLowerCase() === material.toLowerCase(),
    );
  };

  const someDimensionsLarger = (
    p: Product,
    queryStandardDimensions: { [key: string]: number },
  ) => {
    // console.log("Comparing dimensions", p.standardDimensions, queryStandardDimensions);
    return Object.entries(queryStandardDimensions).some(([key, value]) => {
      console.log("key", key, "value", value);
      if (!p.standardDimensions || !p.standardDimensions[key]) return false;
      return p.standardDimensions[key].value > (value as any).value;
    });
  };

  const getWarningBadge = (p: Product) => {
    const matMismatch =
      structuredQuery.grade && !isMaterialMatch(p, structuredQuery.grade);
    console.log(structuredQuery);
    const largerDims =
      structuredQuery.standardDimensions &&
      someDimensionsLarger(p, structuredQuery.standardDimensions);

    console.log("matMismatch", matMismatch);
    console.log("largerDims", largerDims);

    if (!matMismatch && !largerDims) return null;
    let cl;
    if (matMismatch && largerDims) cl = DIFFERENT_MATERIAL_AND_DIMENSIONS;
    else if (matMismatch) cl = DIFFERENT_MATERIAL;
    else if (largerDims) cl = DIFFERENT_DIMENSIONS;

    console.log("cl", cl);

    return (
      <div className="h-5 w-5 relative top-[-2em] left-[-2em]">
        <a className={cl}>
          <ExclamationCircleIcon
            className="h-5 w-5"
            style={{ fill: "orange" }}
          />
        </a>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 mx-2 lg:mx-0">
      {products.map((p, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] border border-gray-200 rounded-lg p-5 shadow-md flex flex-col justify-between"
        >
          {getWarningBadge(p)}
          <h3 className="font-semibold mb-7">{p.summary}</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 text-sm">
            <div className="group flex gap-2" title="Material">
              <ClipboardDocumentListIcon className="w-5 h-5 shrink-0" />
              {extractMaterialString(p)}
            </div>
            <div className="group flex gap-2" title="Shape">
              <ShapesIcon className="w-5 h-5 shrink-0" />
              {p.shape}
            </div>
            <div className="group flex gap-2" title="Price">
              <BanknotesIcon className="w-5 h-5 shrink-0" />
              {formatPrice(p)}
            </div>
            <div className="group flex gap-2">
              <DimensionsIcon className="w-5 h-5 shrink-0" title="Dimensions" />
              {formatDimensions(p, dimensionUnit)}
            </div>
            <div className="group flex gap-2">
              <BuildingOfficeIcon
                className="w-5 h-5 shrink-0"
                title="Supplier"
              />
              {p.supplierName || "Unknown"}
            </div>
            {p.location && (
              <div className="group flex gap-2" title="Location">
                <MapPinIcon className="w-5 h-5 shrink-0" />
                {p.location}
              </div>
            )}
            {p.quantity && (
              <div className="group flex gap-2" title="Quantity">
                <CountIcon className="w-5 h-5 shrink-0" />
                {p.quantity.value} {p.quantity.unit}
              </div>
            )}
            {p.url && p.url.startsWith("http") && (
              <div className="group flex gap-2">
                <LinkIcon className="w-5 h-5 shrink-0" />
                <a
                  href={p.url}
                  className="border-b border-dashed border-black"
                  target="_blank"
                >
                  Link
                </a>
              </div>
            )}
          </div>
          <div className="mt-5">{sourceCategoryBadge(p)}</div>
        </div>
      ))}

      <Tooltip anchorSelect={`.${DIFFERENT_MATERIAL}`} place="right">
        <span>Not an exact material match</span>
      </Tooltip>
      <Tooltip anchorSelect={`.${DIFFERENT_DIMENSIONS}`} place="right">
        <span>Not an exact dimension match</span>
      </Tooltip>
      <Tooltip
        anchorSelect={`.${DIFFERENT_MATERIAL_AND_DIMENSIONS}`}
        place="right"
      >
        <span>Not an exact material and dimension match</span>
      </Tooltip>
    </div>
  );
};

export default SearchResultsGrid;
