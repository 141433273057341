import { FormEvent, useState } from "react";
import {
  saveInputChangeInHookState,
  sendAuthenticatedRequest,
} from "../../common/functions";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import SearchResultsView from "./SearchResultsView";
import { getReranked } from "./rerank";
import MagnifyLoader from "../MagnifyLoader/MagnifyLoader";

function SearchBar(props: SearchBarProps) {
  const [searchInput, setSearchInput] = useState("");
  const [structureResult, setStrucutreResult] = useState<any>();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    const currInput = searchInput;
    e.preventDefault();
    console.log("Form submitted");
    setStrucutreResult(undefined);
    props.onNewSearchInProgress();

    const searchResults = await sendAuthenticatedRequest(
      `${process.env.REACT_APP_SEARCH_JS_API_BASE_URL}?query=${searchInput}`,
      {
        method: "GET",
      },
    ).then((resp) => {
      console.log("Structure response retrieved");
      return resp.json();
    });

    props.onSearchResultsChanged(
      searchResults.results.map((r: any) => r._source),
      searchResults.structure,
      currInput,
    );
    setStrucutreResult(searchResults.structure);
  };

  const formatQueryStructureDebugComponent = (component: any) => {
    if (
      typeof component === "object" &&
      !Array.isArray(component) &&
      Object.values(component).every(
        (v) => typeof v === "object" && !!(v as any).value && !!(v as any).unit,
      )
    ) {
      return (
        <>
          {Object.keys(component).map((k) => (
            <span>
              {k} {component[k].value} {component[k].unit}{" "}
            </span>
          ))}
        </>
      );
    }
    return JSON.stringify(component).replace(/^"+|"+$/g, "");
  };

  return (
    <div className="mb-8 mx-2 lg:mx-0">
      <form onSubmit={handleSubmit}>
        <MagnifyingGlassIcon className=" h-8 w-8 relative fa fa-search text-gray-400 top-[2.5em] left-2 h-14" />
        <input
          className="border-2 rounded p-2 pl-11 w-full"
          onChange={saveInputChangeInHookState(setSearchInput)}
        />
      </form>
      {!!structureResult && (
        <div className="text-sm mt-3">
          Debug information:{" "}
          {Object.keys(structureResult || {}).map((k) => (
            <>
              <span className="pl-3 font-medium">{k}</span>:{" "}
              {formatQueryStructureDebugComponent(structureResult[k])}
            </>
          ))}
        </div>
      )}
    </div>
  );
}

type SearchBarProps = {
  onSearchResultsChanged: (
    searchResults: any[],
    structureResults: any,
    query?: string | undefined,
  ) => void;
  onNewSearchInProgress: () => void;
};

export default function InventorySearch() {
  const [rerankedSearchResults, setRerankedSearchResults] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | undefined>("");
  const [loading, setLoading] = useState(false);
  const [structuredQuery, setStructuredQuery] = useState<any>();

  return (
    <div className="mt-10">
      <SearchBar
        onSearchResultsChanged={(srs, strs, q) => {
          setLoading(false);
          setRerankedSearchResults(getReranked(srs, strs));
          setSearchQuery(q);
          setStructuredQuery(strs);
        }}
        onNewSearchInProgress={() => {
          setLoading(true);
        }}
      />

      {loading && (
        <div className="text-center">
          <MagnifyLoader />
        </div>
      )}
      {!loading && (
        <SearchResultsView
          products={rerankedSearchResults.map((p) => {
            return {
              id: p.id,
              summary: p.summary,
              material: p.material,
              alloy: p.alloy,
              grade: p.grade,
              shape: p.shape,
              price: p.price,
              dimensions: p.dimensions,
              standardDimensions: p.standardDimensions,
              supplierName: p.supplier,
              url: p.url,
              sourceCategory: p.sourceCategory,
              location: p.location,
              quantity: p.quantity,
            };
          })}
          query={searchQuery}
          structuredQuery={structuredQuery}
        />
      )}
    </div>
  );
}
